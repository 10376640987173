module.exports = {
  MAIN_URL: 'https://ls01.tv/',
  URL_CHAT: 'https://a2zchat.com',
  URL_DOWNLOAD_APP: 'https://luongson2.app',
  SECRET_KEY_SOCKET_CHAT: 'luongsonchat@luongsontv@okvip!@#',
  chat: {
    // API_URL: 'http://localhost:5555/api',
    // SOCKET_URL: 'http://localhost:7777'
    API_URL: 'https://api.luongson.chat/api',
    SOCKET_URL: 'https://ws.luongson.chat'
  },
  api: {
    // API_URL: 'https://v1.553328.com/api',
    API_URL: 'https://api-ls.cdnokvip.com/api',
    // API_URL: 'https://api203.553328.com/api',
    // API_URL: 'https://api1.553328.com/api',
    API_PROMOTION_URL: 'https://promotions.okvip.info/api'
    // API_PROMOTION_URL: 'http://localhost:8888/api'
  },
  signalr_url: 'https://wss-ls.cdnokvip.com/dashboardHub',
  signalr_odds_url: 'https://odds.luongson.app/oddsHub',
  aff: {
    URL_NEW88: 'https://livels1.com',
    URL_SHBET: 'https://livels2.com',
    URL_789BET: 'https://livels3.com',
    URL_HI88: 'https://livels4.com',
    URL_JUN881: 'https://livels5.com',
    URL_JUN882: 'https://livels5.com',
    URL_F8BET: 'https://livels6.com',
    URL_MB66: 'https://livels7.com',
    URL_OK9: 'https://livels8.com',
    URL_OKVIP: ''
  },
  social: {
    // URL_VIDEO_ADS: 'https://media.553328.com/videos/luongson.m3u8',
    URL_VIDEO_ADS:
      'https://customer-uec3o7kynak8rx3c.cloudflarestream.com/f6545170b00d5070e9b8bffa34a6be13/manifest/video.m3u8',
    TELEGRAM: 'https://t.me/keoviptv',
    PARTNER: 'https://partner.luongson.me/',
    URL_VQMN: 'https://bit.ly/minigame68'
  },
  GA: {
    GA_MEASUREMENT_ID: 'G-FQW9DGHX1J'
  },
  GG_APP_ID: '891612924395-tkjllkgd56nsume4cf241tm488drc5rc.apps.googleusercontent.com'
};
